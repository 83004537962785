import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class UserSettingsService {
  getUserColumns(userId, view) {
    return axios
      .get(`${config["apiUrl"]}userSettings/columns/${userId}/${view}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  createUpdateColumns(bodyParms) {
    return axios
      .post(
        `${config["apiUrl"]}userSettings/columns`,
        bodyParms,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  saveFilter(bodyParms) {
    return axios
      .post(
        `${config["apiUrl"]}userSettings/filters`,
        bodyParms,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateFilter(filterId, bodyParms) {
    return axios
      .put(
        `${config["apiUrl"]}userSettings/filters/${filterId}`,
        bodyParms,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getFilter(view) {
    return axios
      .get(`${config["apiUrl"]}userSettings/filters/${view}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  deleteFilter(id) {
    return axios
      .delete(`${config["apiUrl"]}userSettings/filters/${id}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

}
export default new UserSettingsService();
