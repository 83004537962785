<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Salvar Filtro'"
      :modal="true"
      :closable="false"
    >
      <form
        name="CrmSaveFilter"
        class="p-col-12"
        @submit.prevent="saveFilter"
        style="min-height: 15vh"
      >
        <div class="p-fluid p-formgrid p-grid" style="min-height: 20vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <InputText
                name="filterName"
                v-model="filterName"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('filterName') },
                  'form-control',
                ]"
              />
              <label for="filterName">Nome</label>
            </span>
            <small v-if="errors.has('filterName')" class="p-error" role="alert">
              Nome é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-save"
          class="p-button-success"
          @click="saveFilter"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import userSettingsService from "../../services/userSettings.service";

export default {
  name: "CrmSaveFilter",
  props: ["show", "filters"],
  data() {
    return {
      filterName: null,
    };
  },
  methods: {
    cancel() {
      this.filterName = null;
      return this.$emit("cancel");
    },
    saveFilter() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        this.$validator.pause();
        this.$validator.reset();
        let bodyParams = {
          user_id: this.$store.state.auth.user.id,
          view: "crmV2",
          name: this.filterName,
          filters: JSON.stringify(this.filters),
          is_public: 0,
        };
        return userSettingsService.saveFilter(bodyParams).then((response) => {
          if (!response) {
              this.$toast.add({
                severity: "error",
                summary: `Erro ao salvar o filtro`,
                detail: "",
                life: 3000,
              });
            return this.$emit("addedFilter", {
              status: "error",
              filter: {},
            });
          }
            this.$toast.add({
              severity: "success",
              summary: `Filtro Gravado`,
              detail: "",
              life: 3000,
            });
          this.filterName = null;
          return this.$emit("addedFilter", {
            status: "ok",
            filter: response,
          });
        });
      });
    },
  },
};
</script>
