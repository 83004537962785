<template>
  <div>
    <h1>CRM</h1>
    <ConfirmDialog />
    <ChangeStatus
      :showToast="true"
      :show="proposalChangeStatus.show"
      :proposalId="proposalChangeStatus.proposalId"
      :proposalSubject="proposalChangeStatus.proposalSubject"
      :statusId="proposalChangeStatus.statusId"
      :selected="proposalChangeStatus.selected"
      v-on:cancel="changeStatusCancel"
      v-on:changedProposalStatus="changeStatusOk"
    />
    <ChangeClosingProbability
      :showToast="true"
      :show="proposalClosingProbability.show"
      :proposalId="proposalClosingProbability.proposalId"
      :proposalSubject="proposalClosingProbability.proposalSubject"
      :closingProbability="proposalClosingProbability.probability"
      :selected="proposalClosingProbability.selected"
      v-on:cancel="changeClosingProbabilityCancel"
      v-on:changedProposalClosingProbability="changeClosingProbabilityOk"
    />
    <ChangeNextContact
      :showToast="true"
      :show="proposalNextContact.show"
      :proposalId="proposalNextContact.proposalId"
      :proposalSubject="proposalNextContact.proposalSubject"
      :nextContact="proposalNextContact.nextContact"
      :selected="proposalNextContact.selected"
      v-on:cancel="changeNextContactCancel"
      v-on:changedNextContact="changeNextContactOk"
    />
    <ChangeSeller
      :showToast="true"
      :show="proposalChangeSeller.show"
      :proposalId="proposalChangeSeller.proposalId"
      :proposalSubject="proposalChangeSeller.proposalSubject"
      :sellerId="proposalChangeSeller.sellerId"
      :selected="proposalChangeSeller.selected"
      v-on:cancel="changeSellerCancel"
      v-on:changedProposalSeller="changeSellerOk"
    />
    <ChangeClient
      :showToast="true"
      :show="proposalChangeClient.show"
      :proposalId="proposalChangeClient.proposalId"
      :proposalSubject="proposalChangeClient.proposalSubject"
      :clientTypeId="proposalChangeClient.clientTypeId"
      :clientName="proposalChangeClient.potential_client_name"
      :clientPerson="proposalChangeClient.potential_client_person"
      :clientTelephone="proposalChangeClient.potential_client_telephone"
      :clientEmail="proposalChangeClient.potential_client_email"
      :clientComments="proposalChangeClient.potential_client_comments"
      :clientContactId="proposalChangeClient.contactId"
      :selected="proposalChangeClient.selected"
      v-on:cancel="changeClientCancel"
      v-on:changedClientType="changeClientOk"
    />
    <ChangePriority
      :showToast="true"
      :show="proposalChangePriority.show"
      :proposalId="proposalChangePriority.proposalId"
      :proposalSubject="proposalChangePriority.proposalSubject"
      :priorityId="proposalChangePriority.priorityId"
      :selected="proposalChangePriority.selected"
      v-on:cancel="changePriorityCancel"
      v-on:changedProposalPriority="changePriorityOk"
    />
    <MergeLeads
      :showToast="true"
      :show="showMergeLead"
      :selected="selectedProposals"
      v-on:cancel="showMergeLead = false"
      v-on:mergedLeads="mergedLeadsOk"
    />
    <LeadForm
      :show="showLeadForm"
      v-on:cancel="showLeadForm = false"
      v-on:createdLead="createdLead"
    />
    <SaveFilter
      :show="showFilterForm"
      :filters="search"
      v-on:cancel="showFilterForm = false"
      v-on:addedFilter="filterSaved"
    />
    <Panel :toggleable="true">
      <template #header>
        <div class="p-d-flex p-ai-center">
          <span class="p-text-bold p-mr-2">Filtros</span>
          <Button
            type="button"
            icon="pi pi-filter"
            :label="search.filterLabel"
            class="p-button-sm p-ml-2 p-mr-1 p-button-secondary"
            @click="toggleFilters"
            aria:haspopup="true"
            aria-controls="overlay_panel"
          />
          <OverlayPanel
            ref="op"
            appendTo="body"
            :showCloseIcon="true"
            id="overlay_panel"
          >
            <DataTable
              :value="filterList"
              :filters.sync="filterFilters"
              filterDisplay="row"
              selectionMode="single"
              class="p-datatable-sm"
              :paginator="true"
              :rows="10"
              @row-select="myFiltersChanged"
              responsiveLayout="scroll"
            >
              <Column field="name" header="Name" sortable filterField="name">
                <template #filter="{ filterModel, filterCallback }">
                  <InputText
                    type="text"
                    class="p-column-filter"
                    v-model="filterModel.value"
                    @input="filterCallback()"
                  />
                </template>
              </Column>
              <Column :columnKey="'actions'" :reorderableColumn="false">
                <template #body="slotProps">
                  <Button
                    icon="pi pi-pencil"
                    class="p-button-text p-button-success"
                    @click="updateFilter(slotProps)"
                  />
                  <Button
                    icon="pi pi-trash"
                    class="p-button-text p-button-danger"
                    @click="deleteFilter(slotProps)"
                  />
                </template>
              </Column>
            </DataTable>
          </OverlayPanel>
          <Button
            v-if="editingFilter"
            icon="pi pi-save"
            class="p-button-sm p-button-success p-mr-1"
            @click="saveFiltersChanges()"
          />
          <Button
            v-if="editingFilter"
            icon="pi pi-ban"
            class="p-button-sm p-button-danger"
            @click="cancelFiltersChanges()"
          />
        </div>
      </template>
      <template #icons>
        <Button
          v-if="showSearchButton == true"
          icon="pi pi-search"
          class="p-button-sm"
          @click="initStreak()"
        />
        <Button
          label="Criar Lead"
          icon="pi pi-plus"
          class="p-button-sm p-button-text p-button-secondary"
          @click="showLeadForm = true"
        />
        <Button
          v-if="can('seeCRMGraphics')"
          label="Gráficos"
          icon="pi pi-chart-bar"
          class="p-button-sm p-button-text p-button-secondary"
          @click="$router.push(`/crm/graphics`)"
        />
        <Button
          v-if="search.filter == null"
          label="Salvar Filtro"
          icon="pi pi-save"
          class="p-button-sm p-button-text p-button-secondary"
          @click="showFilterForm = true"
        />
        <Button
          icon="pi pi-eraser"
          class="p-button-sm p-button-text p-button-secondary"
          label="Limpar Cache"
          @click="clearCache()"
        />
      </template>
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-2">
          <label for="groupBy" class="p-text-bold">Agrupar Por</label>
          <Dropdown
            name="groupBy"
            id="groupBy"
            v-model="search.groupBy"
            :options="groupByList"
            optionValue="value"
            optionLabel="label"
            @input="groupByChanged()"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label for="subject" class="p-text-bold">Assunto</label>
          <InputText
            id="subject"
            name="subject"
            v-model="search.subject"
            @input="filtersChanged()"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label for="status" class="p-text-bold">Estado</label>
          <MultiSelect
            name="status"
            id="status"
            v-model="search.status"
            :options="statusList"
            optionValue="id"
            optionLabel="title"
            :showClear="true"
            @input="filtersChanged()"
          />
        </div>
        <div class="p-field p-col-12 p-md-1">
          <label for="closingProbability" class="p-text-bold"
            >Prob. Fecho</label
          >
          <MultiSelect
            name="closingProbability"
            id="closingProbability"
            v-model="search.closingProbability"
            :options="closingProbabilityList"
            optionValue="value"
            optionLabel="label"
            :showClear="true"
            @input="filtersChanged()"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label for="clientType" class="p-text-bold">Tipo de Cliente</label>
          <MultiSelect
            name="clientType"
            id="clientType"
            v-model="search.clientType"
            :options="clientTypeList"
            optionValue="id"
            optionLabel="name"
            :showClear="true"
            @input="filtersChanged()"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label for="nextContact" class="p-text-bold">Próximo Contacto</label>
          <v-date-picker
            name="nextContact"
            id="nextContact"
            v-model="search.nextContact"
            @dayclick="filtersChanged()"
            :masks="{
              input: 'DD-MM-YYYY',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
        <div class="p-field p-col-12 p-md-2" v-if="can('SeeAllCrmProposals')">
          <label for="seller" class="p-text-bold">Vendedor</label>
          <MultiSelect
            name="seller"
            id="seller"
            v-model="search.seller"
            :options="sellerList"
            optionValue="id"
            optionLabel="name"
            :showClear="true"
            @input="filtersChanged()"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label for="priority" class="p-text-bold">Prioridade</label>
          <MultiSelect
            name="priority"
            id="priority"
            v-model="search.priority"
            :options="priorityList"
            optionValue="priority"
            optionLabel="name"
            :showClear="true"
            @input="filtersChanged()"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label for="createDate" class="p-text-bold">Data de Criação</label>
          <vue-quarter-select
            name="createDate"
            id="createDate"
            v-model="search.createDate"
            placeholder=" "
            placement="bottom"
            popper-class="quarter-wrapper"
            :multiple="true"
            class="quarter-select"
            size="medium"
            @change="filtersChanged()"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label for="proposalDate" class="p-text-bold">Data de Proposta</label>
          <vue-quarter-select
            name="proposalDate"
            id="proposalDate"
            v-model="search.proposalDate"
            placeholder=" "
            placement="bottom"
            popper-class="quarter-wrapper"
            :multiple="true"
            class="quarter-select"
            size="medium"
            @change="filtersChanged()"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label for="expectedSaleDate" class="p-text-bold">
            Data de Prevista Venda
          </label>
          <vue-quarter-select
            name="expectedSaleDate"
            id="expectedSaleDate"
            v-model="search.expectedSaleDate"
            placeholder=" "
            placement="bottom"
            popper-class="quarter-wrapper"
            :multiple="true"
            class="quarter-select"
            size="medium"
            @change="filtersChanged()"
          />
        </div>
        <div class="p-field p-col-12 p-md-2">
          <label for="saleDate" class="p-text-bold">Data de Venda</label>
          <vue-quarter-select
            name="saleDate"
            id="saleDate"
            v-model="search.saleDate"
            placeholder=" "
            placement="bottom"
            popper-class="quarter-wrapper"
            :multiple="true"
            class="quarter-select"
            size="medium"
            @change="filtersChanged()"
          />
        </div>
      </div>
    </Panel>
    <div class="p-grid p-fluid dashboard p-jc-center p-mt-2">
      <divider />
      <W4Mstreak
        :states="streakStatus"
        :loader="loading"
        v-on:W4MStreakChangeSelected="streakSelectedChange"
      />
    </div>
    <div style="height: 65vh">
      <DataTable
        :key="tableKey"
        ref="streakGridV2"
        :value="data"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortField="sortField"
        responsiveLayout="scroll"
        rowGroupMode="subheader"
        :groupRowsBy="groupByData"
        :expandableRowGroups="true"
        :expandedRowGroups.sync="expandedRowGroups"
        :scrollable="true"
        scrollHeight="flex"
        :selection.sync="selectedProposals"
        :reorderableColumns="true"
        :columns="selectedColumns"
        :resizableColumns="true"
        columnResizeMode="fit"
        stateStorage="local"
        stateKey="streakTableStorageV2"
        @state-restore="restoreState"
        @state-save="saveState"
      >
        <template #header>
          <div class="table-header p-d-flex p-jc-between p-ai-center">
            <div id="left" class="p-d-flex p-ai-center">
              <Button
                v-if="selectedProposals && selectedProposals.length > 1"
                icon="pi pi-sitemap"
                class="p-button-sm p-mr-1"
                label="Merge Leads"
                @click="showMergeLead = true"
              />
              <MultiSelect
                v-model="selectedColumns"
                :options="columns"
                :showToggleAll="false"
                optionLabel="header"
                placeholder="Colunas"
                optionDisabled="disabled"
                @input="columnsChanged"
                style="width: 25em"
                class="p-mr-1"
              />
            </div>
          </div>
        </template>
        <template #empty>
          Nada a mostrar. Selecione no menu em cima o que pretende visualizar.
        </template>
        <template #loading> A carregar dados. Por favor Aguarde... </template>
        <template #groupheader="slotProps">
          <div
            class="myGroupHeader"
            :style="getGroupheaderColor(slotProps.data)"
          >
            {{ getGroupHeader(slotProps.data[search.groupBy]) }}
          </div>
        </template>
        <Column :field="groupByData"></Column>

        <Column
          :reorderableColumn="false"
          selectionMode="multiple"
          :headerStyle="{ width: '3%' }"
          :filterHeaderStyle="{ width: '3%' }"
          :bodyStyle="{ width: '3%' }"
        ></Column>
        <Column
          :reorderableColumn="false"
          sortable
          field="id"
          header="Id"
          filterField="id"
          :headerStyle="{ width: '6%' }"
          :filterHeaderStyle="{ width: '6%' }"
          :bodyStyle="{ width: '6%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Ver Proposta'"
              @click="viewProposal(slotProps.data.id)"
              >{{ slotProps.data.id }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          :reorderableColumn="false"
          sortable
          field="cleanSubject"
          header="Assunto"
          filterField="cleanSubject"
          :headerStyle="{ width: '21%' }"
          :filterHeaderStyle="{ width: '21%' }"
          :bodyStyle="{ width: '21%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.cleanSubject }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          sortable
          field="status"
          filterField="status"
          header="Estado"
          :headerStyle="{ width: '8%' }"
          :filterHeaderStyle="{ width: '8%' }"
          :bodyStyle="{ width: '8%' }"
        >
          <template #body="slotProps">
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.assigned_to ||
                !slotProps.data.assigned_to
              "
              class="clickable-item"
              v-tooltip="'Alterar Estado'"
              @click="changeStatus(slotProps.data)"
            >
              {{ slotProps.data.proposal_status.name }}
            </span>
            <span v-else>
              {{ slotProps.data.proposal_status.name }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <MultiSelect
              v-model="filterModel.value"
              @input="filterCallback()"
              appendTo="body"
              :options="streakStatus"
              optionValue="id"
              optionLabel="title"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            />
          </template>
        </Column>
        <Column
          v-if="showColumn('sale_values')"
          header="Valores (Venda / Mensalidade / Custo)"
          :headerStyle="{ width: '10%', 'white-space': 'normal' }"
          :filterHeaderStyle="{ width: '10%' }"
          :bodyStyle="{
            width: '10%',
            'text-align': 'center',
            display: 'table-cell',
          }"
          :headerClass="'align-center'"
        >
          <template #body="slotProps">
            {{
              toEuro(slotProps.data.sale_value ? slotProps.data.sale_value : 0)
            }}
            /
            {{
              toEuro(
                slotProps.data.monthly_sales_value
                  ? slotProps.data.monthly_sales_value
                  : 0
              )
            }}
            /
            {{ toEuro(slotProps.data.cost ? slotProps.data.cost : 0) }}
          </template>
        </Column>
        <Column
          v-if="showColumn('closing_probability')"
          sortable
          field="closing_probability"
          header="Prob. Fecho"
          filterField="closing_probability"
          :headerStyle="{ width: '10%' }"
          :filterHeaderStyle="{ width: '10%' }"
          :bodyStyle="{ width: '10%' }"
        >
          <template #body="slotProps">
            <span
              v-if="slotProps.data.blocked == 0"
              class="clickable-item"
              v-tooltip="'Alterar Probabilidade de Fecho'"
              @click="changeClosingProbability(slotProps.data)"
            >
              {{ slotProps.data.closing_probability }} %
            </span>
            <span v-else> {{ slotProps.data.closing_probability }} % </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <MultiSelect
              id="closing_probability"
              appendTo="body"
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="closingProbabilityList"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            />
          </template>
        </Column>
        <Column
          v-if="showColumn('client_type')"
          field="client_type"
          header="Tipo Cliente"
          sortable
          filterField="client_type"
          :headerStyle="{ width: '12%' }"
          :filterHeaderStyle="{ width: '12%' }"
          :bodyStyle="{ width: '12%' }"
        >
          <template #body="slotProps">
            <span
              v-if="slotProps.data.blocked == 0"
              class="clickable-item"
              v-tooltip="'Alterar Tipo de Cliente'"
              @click="changeClient(slotProps.data)"
            >
              {{
                !slotProps.data.client_type
                  ? "_______"
                  : slotProps.data.type_client.name
              }}
            </span>
            <span v-else>
              {{
                !slotProps.data.client_type
                  ? ""
                  : slotProps.data.type_client.name
              }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <MultiSelect
              id="client_temp"
              appendTo="body"
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="clientTypeList"
              optionValue="id"
              optionLabel="name"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            />
          </template>
        </Column>
        <Column
          v-if="showColumn('nextContact')"
          sortable
          field="nextContact"
          header="Próximo Contacto"
          filterField="nextContact"
          dataType="date"
          :headerStyle="{ width: '11%' }"
          :filterHeaderStyle="{ width: '11%' }"
          :bodyStyle="{ width: '11%' }"
        >
          <template #body="slotProps">
            <span
              v-if="slotProps.data.blocked == 0"
              class="clickable-item"
              v-tooltip="'Alterar Próximo Contacto'"
              @click="changeNextContact(slotProps.data)"
              >{{
                slotProps.data.nextContact
                  ? getDateTime(slotProps.data.nextContact)
                  : "_______"
              }}</span
            >
            <span v-else>
              {{
                slotProps.data.nextContact
                  ? getDateTime(slotProps.data.nextContact)
                  : ""
              }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column
          v-if="showColumn('seller_id')"
          sortable
          field="seller_id"
          header="Vendedor"
          filterField="seller_id"
          :headerStyle="{ width: '11%' }"
          :filterHeaderStyle="{ width: '11%' }"
          :bodyStyle="{ width: '11%' }"
        >
          <template #body="slotProps">
            <span
              v-if="slotProps.data.blocked == 0"
              class="clickable-item"
              v-tooltip="'Alterar Vendedor'"
              @click="changeSeller(slotProps.data)"
              >{{
                slotProps.data.seller && slotProps.data.seller_name.trim() != ""
                  ? slotProps.data.seller_name
                  : "_______"
              }}</span
            >
            <span v-else>
              {{
                slotProps.data.seller && slotProps.data.seller_name.trim() != ""
                  ? slotProps.data.seller_name
                  : ""
              }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <MultiSelect
              id="closing_probability"
              appendTo="body"
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="sellerList"
              optionValue="seller_id"
              optionLabel="seller_name"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            />
          </template>
        </Column>
        <Column
          v-if="showColumn('createdDaysAgo')"
          sortable
          field="createdDaysAgo"
          header="Criado"
          :headerStyle="{ width: '8%' }"
          :filterHeaderStyle="{ width: '8%' }"
          :bodyStyle="{ width: '8%' }"
        >
          <template #body="slotProps">
            <span>{{ slotProps.data.createdDaysAgo }} dias</span>
          </template>
        </Column>
        <Column
          v-if="showColumn('priority')"
          sortable
          field="priority"
          header="Prioridade"
          filterField="priority"
          :headerStyle="{ width: '10%' }"
          :filterHeaderStyle="{ width: '10%' }"
          :bodyStyle="{ width: '10%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Alterar Prioridade'"
              @click="changePriority(slotProps.data)"
            >
              {{ slotProps.data.proposal_priority.name }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <MultiSelect
              v-model="filterModel.value"
              appendTo="body"
              @input="filterCallback()"
              :options="priorityList"
              optionValue="priority"
              optionLabel="name"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            />
          </template>
        </Column>
        <Column
          v-if="showColumn('contactName')"
          sortable
          field="contactName"
          header="Contacto"
          filterField="contactName"
          :headerStyle="{ width: '11%' }"
          :filterHeaderStyle="{ width: '11%' }"
          :bodyStyle="{ width: '11%' }"
        >
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Alterar Contacto'"
              @click="changeClient(slotProps.data)"
            >
              {{
                slotProps.data.contactName.trim() == ""
                  ? "_______"
                  : slotProps.data.contactName
              }}
            </span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          v-if="showColumn('created_at')"
          sortable
          field="created_at"
          header="Data Criação"
          filterField="created_at"
          dataType="date"
          :headerStyle="{ width: '8.5%' }"
          :filterHeaderStyle="{ width: '8.5%' }"
          :bodyStyle="{ width: '8.5%' }"
        >
          <template #body="slotProps">
            <span>{{ getDate(slotProps.data.created_at) }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
        <Column
          v-if="showColumn('createdQuarter')"
          sortable
          field="createdQuarter"
          header="Criado Em"
          filterField="createdQuarter"
          :headerStyle="{ width: '8.5%' }"
          :filterHeaderStyle="{ width: '8.5%' }"
          :bodyStyle="{ width: '8.5%' }"
        >
        </Column>
        <Column
          v-if="showColumn('expectedSaleQuarter')"
          sortable
          field="expectedSaleQuarter"
          header="Data Prev. Venda"
          filterField="expectedSaleQuarter"
          :headerStyle="{ width: '8.5%' }"
          :filterHeaderStyle="{ width: '8.5%' }"
          :bodyStyle="{ width: '8.5%' }"
        >
        </Column>
        <Column
          v-if="showColumn('saleDateQuater')"
          sortable
          field="saleDateQuater"
          header="Data Venda"
          filterField="saleDateQuater"
          :headerStyle="{ width: '8.5%' }"
          :filterHeaderStyle="{ width: '8.5%' }"
          :bodyStyle="{ width: '8.5%' }"
        >
        </Column>
        <Column
          v-if="showColumn('proposalDateQuater')"
          sortable
          field="proposalDateQuater"
          header="Data Proposta"
          filterField="proposalDateQuater"
          :headerStyle="{ width: '8.5%' }"
          :filterHeaderStyle="{ width: '8.5%' }"
          :bodyStyle="{ width: '8.5%' }"
        >
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import LeadForm from "../components/crm/LeadForm.vue";
import SaveFilter from "../components/crm/SaveFilter.vue";
import chroma from "chroma-js";
import Panel from "primevue/panel";
import { FilterMatchMode } from "primevue/api/";
import VueQuarterSelect from "@3scarecrow/vue-quarter-select";
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";
import prospectService from "../services/prospect.service";
import crmService from "../services/crm.service";
import userSettingsService from "../services/userSettings.service";
import { getOnlyDate, getOnlyTime, formatNumber } from "../helpers/helpers";
import ChangeStatus from "../components/crm/ChangeStatus.vue";
import ChangeClosingProbability from "../components/crm/ChangeClosingProbability.vue";
import ChangeNextContact from "../components/crm/ChangeNextContact.vue";
import ChangeSeller from "../components/crm/ChangeSeller.vue";
import ChangeClient from "../components/crm/ChangeClient.vue";
import ChangePriority from "../components/crm/ChangePriority.vue";
import MergeLeads from "../components/crm/MergeLeads.vue";
export default {
  name: "CRM",
  components: {
    W4Mstreak,
    LeadForm,
    SaveFilter,
    VueQuarterSelect,
    Panel,
    ChangeStatus,
    ChangeClosingProbability,
    ChangeNextContact,
    ChangeSeller,
    ChangeClient,
    ChangePriority,
    MergeLeads,
  },
  data() {
    return {
      isInitializing: true,
      showSearchButton: false,
      editingFilter: false,
      tableKey: 0,
      loading: false,
      showLeadForm: false,
      showFilterForm: false,
      data: [],
      streakStatus: [],
      statusList: [],
      closingProbabilityList: [],
      clientTypeList: [],
      sellerList: [],
      priorityList: [],
      groupByList: [],
      filterList: [],
      groupByData: "streakIndex",
      search: {
        groupBy: "status",
        filterLabel: "Os Meus Filtros",
        filter: null,
        subject: null,
        status: null,
        closingProbability: null,
        clientType: null,
        nextContact: null,
        seller: null,
        priority: null,
        createDate: [],
        proposalDate: [],
        expectedSaleDate: [],
        saleDate: [],
      },
      filteredValues: null,
      filterFilters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filters: {
        id: { value: null, matchMode: FilterMatchMode.CONTAINS },
        cleanSubject: { value: null, matchMode: FilterMatchMode.CONTAINS },
        contactName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.IN },
        streakIndex: { value: null, matchMode: FilterMatchMode.IN },
        priority: { value: null, matchMode: FilterMatchMode.IN },
        seller_id: { value: null, matchMode: FilterMatchMode.IN },
        created_at: { value: null, matchMode: FilterMatchMode.DATE_IS },
        nextContact: { value: null, matchMode: FilterMatchMode.DATE_IS },
        closing_probability: { value: null, matchMode: FilterMatchMode.IN },
        client_type: { value: null, matchMode: FilterMatchMode.IN },
      },
      columns: [
        { field: "id", header: "Id", disabled: true },
        { field: "cleanSubject", header: "Assunto", disabled: true },
        { field: "streakIndex", header: "Estado", disabled: true },
        { field: "sale_values", header: "Valores", disabled: false },
        {
          field: "closing_probability",
          header: "Prob. Fecho",
          disabled: false,
        },
        { field: "client_type", header: "Tipo Cliente", disabled: false },
        { field: "nextContact", header: "Próximo Contacto", disabled: false },
        { field: "seller_id", header: "Vendedor", disabled: false },
        { field: "createdDaysAgo", header: "Criado", disabled: false },
        { field: "priority", header: "Prioridade", disabled: false },
        { field: "contactName", header: "Contacto", disabled: false },
        { field: "created_at", header: "Data Criação", disabled: false },
        { field: "createdQuarter", header: "Criado Em", disabled: false },
        {
          field: "expectedSaleQuarter",
          header: "Data Prev. Venda",
          disabled: false,
        },
        { field: "saleDateQuater", header: "Data Venda", disabled: false },
        {
          field: "proposalDateQuater",
          header: "Data Proposta",
          disabled: false,
        },
      ],
      selectedColumns: [
        { field: "id", header: "Id", disabled: true },
        { field: "cleanSubject", header: "Assunto", disabled: true },
        { field: "streakIndex", header: "Estado", disabled: true },
        { field: "sale_values", header: "Valores", disabled: false },
        {
          field: "closing_probability",
          header: "Prob. Fecho",
          disabled: false,
        },
        { field: "client_type", header: "Tipo Cliente", disabled: false },
        { field: "nextContact", header: "Próximo Contacto", disabled: false },
        { field: "seller_id", header: "Vendedor", disabled: false },
        { field: "createdDaysAgo", header: "Criado", disabled: false },
      ],
      sortField: null,
      expandedRowGroups: [],
      selectedProposals: [],
      proposalChangeStatus: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        statusId: null,
      },
      proposalClosingProbability: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        probability: null,
      },
      proposalNextContact: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        nextContact: null,
      },
      proposalChangeSeller: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        sellerId: null,
      },
      proposalChangeClient: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        clientTypeId: null,
        contactId: null,
        potential_client_name: null,
        potential_client_person: null,
        potential_client_telephone: null,
        potential_client_email: null,
        potential_client_comments: null,
      },
      proposalChangePriority: {
        show: false,
        proposalId: null,
        proposalSubject: null,
        priorityId: null,
      },
      showMergeLead: false,
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    this.statusList = this.getStatusList();
    this.closingProbabilityList = this.getClosingProbabilityList();
    this.groupByList = this.getgroupByList();
    this.clientTypeList = await crmService.getClientTypeList();
    this.clientTypeList.unshift({
      id: null,
      name: "Sem Tipo de Cliente",
      color: "#ff0000",
    });
    this.priorityList = await crmService.getPriorityList();
    this.priorityList.unshift({ priority: null, name: "Sem Prioridade" });
    this.filterList = await userSettingsService.getFilter("crmV2");

    if (localStorage.streakColumns != undefined) {
      this.selectedColumns = JSON.parse(localStorage.streakColumns);
    }
    if (localStorage.crmFilters != undefined) {
      let myStorage = JSON.parse(localStorage.crmFilters);
      if (myStorage.filter != null) {
        let myFilter = this.filterList.find(
          (item) => item.id == myStorage.filter
        );
        let filter = JSON.parse(myFilter.filters);
        filter.filter = myFilter.id;
        filter.filterLabel = myFilter.name;
        this.search = { ...filter };
      } else {
        this.search = JSON.parse(localStorage.crmFilters);
      }
    }
    if (this.can("SeeAllCrmProposals")) {
      this.sellerList = await prospectService.getSellers();
    }
    this.isInitializing = false;
    return this.initStreak();
  },
  mounted() {},
  methods: {
    createdLead() {
      return window.location.reload();
    },
    getgroupByList() {
      return [
        { label: "Estado", value: "status" },
        { label: "Tipo de Cliente", value: "client_type" },
        { label: "Vendedor", value: "seller_id" },
        { label: "Prioridade", value: "priority" },
        { label: "Probabilidade de Fecho", value: "closing_probability" },
        { label: "Data de Criação", value: "createdQuarter" },
        { label: "Data de Proposta", value: "proposalDateQuater" },
        { label: "Data de Prevista Venda", value: "expectedSaleQuarter" },
        { label: "Data de Venda", value: "saleDateQuater" },
      ];
    },
    getClosingProbabilityList() {
      return [
        { label: "%", value: null, color: "#b00000" },
        { label: "0%", value: 0, color: "#fa0707" },
        { label: "15%", value: 15, color: "#ff5555" },
        { label: "25%", value: 25, color: "#fa9107" },
        { label: "50%", value: 50, color: "#fac107" },
        { label: "75%", value: 75, color: "#fae607" },
        { label: "90%", value: 90, color: "#b5fa07" },
        { label: "100%", value: 100, color: "#07fa2f" },
      ];
    },
    getStatusList() {
      return [
        {
          backgroudColor: "#5F78DA",
          textColor: "#000",
          title: "Por Classificar",
          subtitle: "",
          counter: 0,
          active: false,
          id: 1,
          index: 0,
        },
        {
          backgroudColor: "#00C8B6",
          textColor: "#000",
          title: "Contactar Cliente",
          subtitle: "",
          counter: 0,
          active: false,
          id: 2,
          index: 1,
        },
        {
          backgroudColor: "#dcf900",
          textColor: "#000",
          title: "Em Progresso",
          subtitle: "",
          counter: 0,
          active: false,
          id: 9,
          index: 2,
        },
        {
          backgroudColor: "#FCC200",
          textColor: "#000",
          title: "Espera Resposta",
          subtitle: "",
          counter: 0,
          active: false,
          id: 3,
          index: 3,
        },
        {
          backgroudColor: "#86e053",
          textColor: "#000",
          title: "Vendido Sem PO",
          subtitle: "",
          counter: 0,
          active: false,
          id: 6,
          index: 4,
        },
        {
          backgroudColor: "#56dd54",
          textColor: "#000",
          title: "Projeto/PHC",
          subtitle: "",
          counter: 0,
          active: false,
          id: 8,
          index: 5,
        },
        {
          backgroudColor: "#e03d23",
          textColor: "#000",
          title: "Não Vendido",
          subtitle: "",
          counter: 0,
          active: false,
          id: 7,
          index: 6,
        },
        {
          backgroudColor: "#855fd8",
          textColor: "#000",
          title: "Piloto",
          subtitle: "",
          counter: 0,
          active: false,
          id: 5,
          index: 7,
        },
      ];
    },
    getStatusToStreak() {
      if (!this.search.status || this.search.status.length == 0) {
        return this.statusList;
      }
      let status = this.statusList.filter((status) =>
        this.search.status.includes(status.id)
      );
      return status;
    },
    getClientTypeToStreak() {
      let clientTypes = [];
      if (!this.search.clientType || this.search.clientType.length == 0) {
        clientTypes = [...this.clientTypeList];
      } else {
        clientTypes = this.clientTypeList.filter((status) =>
          this.search.clientType.includes(status.id)
        );
      }

      return clientTypes.map((clientType, index) => {
        return {
          backgroudColor: clientType.color,
          textColor: "#000",
          title: clientType.name,
          subtitle: "",
          counter: 0,
          active: false,
          id: clientType.id,
          index: index,
        };
      });
    },
    getPriorityToStreak() {
      let prioritySelects = [];
      if (!this.search.priority || this.search.priority.length == 0) {
        prioritySelects = [...this.priorityList];
      } else {
        prioritySelects = this.priorityList.filter((status) =>
          this.search.priority.includes(status.priority)
        );
      }
      let colors = chroma.scale("Paired").colors(prioritySelects.length);
      return prioritySelects.map((priority, index) => {
        return {
          backgroudColor: colors[index],
          textColor: this.getReadableTextColor(colors[index]),
          title: priority.name,
          subtitle: "",
          counter: 0,
          active: false,
          id: priority.priority,
          index: index,
        };
      });
    },
    getSellerToStreak() {
      let sellersSelects = [];
      if (!this.search.seller || this.search.seller.length == 0) {
        sellersSelects = [...this.sellerList];
      } else {
        sellersSelects = this.sellerList.filter((status) =>
          this.search.seller.includes(status.id)
        );
      }
      let colors = chroma.scale("Paired").colors(sellersSelects.length);
      return sellersSelects.map((seller, index) => {
        return {
          backgroudColor: colors[index],
          textColor: this.getReadableTextColor(colors[index]),
          title: seller.name,
          subtitle: "",
          counter: 0,
          active: false,
          id: seller.id,
          index: index,
        };
      });
    },
    getClosingProbabilityToStreak() {
      let probabilitySelects = [];
      if (
        !this.search.closingProbability ||
        this.search.closingProbability.length == 0
      ) {
        probabilitySelects = [...this.closingProbabilityList];
      } else {
        probabilitySelects = this.closingProbabilityList.filter((status) =>
          this.search.closingProbability.includes(status.value)
        );
      }
      return probabilitySelects.map((closingProbability, index) => {
        return {
          backgroudColor: closingProbability.color,
          textColor: this.getReadableTextColor(closingProbability.color),
          title: closingProbability.label,
          subtitle: "",
          counter: 0,
          active: false,
          id: closingProbability.value,
          index: index,
        };
      });
    },
    convertDateToQuarter(date) {
      let newDate = new Date(date);
      let month = newDate.getMonth();
      let quarter = Math.floor(month / 3) + 1;
      let year = newDate.getFullYear();
      return `${year} Q${quarter}`;
    },
    getQuartersToStreak(groupBy) {
      let quarters = [];
      if (
        groupBy === "createdQuarter" &&
        (!this.search.createDate || this.search.createDate.length == 0)
      ) {
        quarters = this.data
          .map((lead) => lead.createdQuarter)
          .filter((value, index, self) => self.indexOf(value) === index);
      } else if (
        groupBy === "expectedSaleQuarter" &&
        (!this.search.expectedSaleDate ||
          this.search.expectedSaleDate.length == 0)
      ) {
        quarters = this.data
          .map((lead) => lead.expectedSaleQuarter)
          .filter((value, index, self) => self.indexOf(value) === index);
      } else if (
        groupBy === "saleDateQuater" &&
        (!this.search.saleDate || this.search.saleDate.length == 0)
      ) {
        quarters = this.data
          .map((lead) => lead.saleDateQuater)
          .filter((value, index, self) => self.indexOf(value) === index);
      } else if (
        groupBy === "proposalDateQuater" &&
        (!this.search.proposalDate || this.search.proposalDate.length == 0)
      ) {
        quarters = this.data
          .map((lead) => lead.proposalDateQuater)
          .filter((value, index, self) => self.indexOf(value) === index);
      } else {
        quarters = this.search.proposalDate.map((date) =>
          this.convertDateToQuarter(date[0])
        );
      }

      let colors = chroma.scale("Paired").colors(quarters.length);
      return quarters.map((quarter, index) => {
        return {
          backgroudColor: colors[index],
          textColor: this.getReadableTextColor(colors[index]),
          title: quarter,
          subtitle: "",
          counter: 0,
          active: false,
          id: quarter,
          index: index,
        };
      });
    },
    async initStreak(getData = true) {
      this.loading = true;
      this.selectedProposals = [];
      if (getData) {
        let params = Object.fromEntries(
          Object.entries(this.search).filter(
            ([, value]) => value !== null && value !== undefined
          )
        );
        if (
          this.search.nextContact != null &&
          this.search.nextContact instanceof Date
        ) {
          params.nextContact = getOnlyDate(this.search.nextContact);
        }
        this.data = this.formatProposals(
          await crmService.advancedSearch(params)
        );
        this.showSearchButton = false;
      }
      if (this.search.groupBy === "status") {
        this.streakStatus = this.getStatusToStreak();
      } else if (this.search.groupBy === "client_type") {
        this.streakStatus = this.getClientTypeToStreak();
      } else if (this.search.groupBy === "priority") {
        this.streakStatus = this.getPriorityToStreak();
      } else if (this.search.groupBy === "seller_id") {
        this.streakStatus = this.getSellerToStreak();
      } else if (this.search.groupBy === "closing_probability") {
        this.streakStatus = this.getClosingProbabilityToStreak();
      } else if (
        this.search.groupBy === "createdQuarter" ||
        this.search.groupBy === "expectedSaleQuarter" ||
        this.search.groupBy === "saleDateQuater" ||
        this.search.groupBy === "proposalDateQuater"
      ) {
        this.streakStatus = this.getQuartersToStreak(this.search.groupBy);
      }

      if (this.search.groupBy === "status") {
        this.groupByData = "streakIndex";
      } else {
        this.groupByData = this.search.groupBy;
      }
      this.sortField = this.groupByData;
      this.countStreark();
      this.getStreakSubtitles();
      this.tableKey++;
      this.expandRows();
      return (this.loading = false);
    },
    expandRows() {
      if (this.groupByData == "streakIndex") {
        this.expandedRowGroups = this.streakStatus.map(
          (status) => status.index
        );
      } else {
        this.expandedRowGroups = this.streakStatus.map((status) => status.id);
      }
      let tableStorage = JSON.parse(localStorage.streakTableStorageV2);
      tableStorage.expandedRowGroups = JSON.stringify(this.expandedRowGroups);
    },
    getStreakSubtitles() {
      this.streakStatus.forEach((status) => {
        let values = {
          cost: this.toEuro(
            this.data.reduce(
              (sum, item) =>
                item[this.search.groupBy] == status.id
                  ? sum + item.cost
                  : sum + 0,
              0
            )
          ),
          value: this.toEuro(
            this.data.reduce(
              (sum, item) =>
                item[this.search.groupBy] == status.id
                  ? sum + item.sale_value
                  : sum + 0,
              0
            )
          ),
          monthly: this.toEuro(
            this.data.reduce(
              (sum, item) =>
                item[this.search.groupBy] == status.id
                  ? sum + item.monthly_sales_value
                  : sum + 0,
              0
            )
          ),
        };
        return (status.subtitle = `V ${values.value} / M ${values.monthly} / C ${values.cost}`);
      });
    },
    countStreark() {
      this.streakStatus.forEach((status) => {
        status.counter = this.data.filter(
          (proposal) => proposal[this.search.groupBy] == status.id
        ).length;
      });
    },
    streakSelectedChange(event) {
      this.expandedRowGroups = [event.currentId];
    },
    showColumn(field) {
      return this.selectedColumns.some((column) => column.field === field);
    },
    columnsChanged() {
      localStorage.streakColumns = JSON.stringify(this.selectedColumns);
    },
    getGroupHeader(id) {
      if (
        this.search.groupBy == "createdQuarter" ||
        this.search.groupBy == "proposalDateQuater" ||
        this.search.groupBy == "expectedSaleQuarter" ||
        this.search.groupBy == "saleDateQuater"
      ) {
        return id;
      }
      let column = this.streakStatus.find((status) => status.id == id);
      if (!column) {
        return "Não Definido";
      }
      return column.title;
    },
    getGroupheaderColor(info) {
      let statusInfo = this.streakStatus.find(
        (status) => status.id == info[this.search.groupBy]
      );
      if (!statusInfo) {
        return {};
      }
      return {
        backgroundColor: statusInfo.backgroudColor,
        borderColor: statusInfo.backgroudColor,
        color: statusInfo.textColor,
      };
    },
    toEuro(value) {
      return `${formatNumber(value)}`;
    },
    getDateTime(date) {
      return `${getOnlyDate(date)} ${getOnlyTime(date)}`;
    },
    getDate(date) {
      return `${getOnlyDate(date)}`;
    },
    findStreakStatusIndex(statusId) {
      return this.getStatusList().findIndex((status) => status.id == statusId);
    },
    formatProposals(proposalsList, streakIndex) {
      let proposal = proposalsList.map((v) => ({
        ...v,
        streakIndex:
          streakIndex !== undefined
            ? streakIndex
            : this.findStreakStatusIndex(v.status),
        created_at: new Date(`${v.created_at}`),
        updated_at: new Date(`${v.updated_at}`),
        client_name: v.client ? v.client.name : null,
        seller_name: v.seller ? v.seller.name : null,
        type_name: v.type ? v.type.name : null,
        contactName:
          v.contact_id && v.clientType == 1
            ? v.contact.name
            : `${
                v.potential_client_name ? v.potential_client_name + " | " : ""
              } ${v.potential_client_person ? v.potential_client_person : ""}`,
        nextContact: v.next_contact ? new Date(`${v.next_contact}`) : null,
      }));
      return proposal;
    },
    clearCache() {
      localStorage.removeItem("streakColumns");
      localStorage.removeItem("crmFilters");
      localStorage.removeItem("streakTableStorageV2");
      this.$router.go();
    },
    viewProposal(proposalId) {
      let route = this.$router.resolve({
        path: `/crm/lead/${proposalId}`,
      });
      window.open(route.href, "_blank");
    },
    getReadableTextColor(backgroundColor) {
      const whiteContrast = chroma.contrast(backgroundColor, "#ffffff");
      const blackContrast = chroma.contrast(backgroundColor, "#000000");
      return whiteContrast > blackContrast ? "#ffffff" : "#000000";
    },
    myFiltersChanged(event) {
      this.editingFilter = false;
      this.$refs.op.hide();
      this.filterFilters.name.value = null;
      if (this.isInitializing) {
        return;
      }
      this.search.filter = event.data.id;
      this.search.filterLabel = event.data.name;
      if (this.search.filter == null) {
        this.search.filterLabel = "Os Meus Filtros";
        return this.clearSearch();
      }
      let myFilter = this.filterList.find(
        (filter) => filter.id == this.search.filter
      );
      let newSearch = JSON.parse(myFilter.filters);

      newSearch.filter = myFilter.id;
      newSearch.filterLabel = myFilter.name;
      localStorage.crmFilters = JSON.stringify(newSearch);
      this.search = newSearch;
      return this.initStreak();
    },
    filtersChanged() {
      if (this.isInitializing) {
        return;
      }
      if (!this.editingFilter) {
        this.search.filter = null;
        this.search.filterLabel = "Os Meus Filtros";
        localStorage.crmFilters = JSON.stringify(this.search);
      }
      return (this.showSearchButton = true);
    },
    groupByChanged() {
      if (this.isInitializing) {
        return;
      }
      if (!this.editingFilter) {
        this.search.filter = null;
        this.search.filterLabel = "Os Meus Filtros";
        localStorage.crmFilters = JSON.stringify(this.search);
      }
      return this.initStreak(false);
    },
    filterSaved(info) {
      if (info.status == "ok") {
        this.filterList.push(info.filter);
        this.search.filter = info.filter.id;
        this.search.filterLabel = info.filter.name;
        localStorage.crmFilters = JSON.stringify(this.search);
      }
      return (this.showFilterForm = false);
    },
    clearSearch() {
      this.search = {
        groupBy: "status",
        filter: null,
        subject: null,
        status: null,
        closingProbability: null,
        clientType: null,
        nextContact: null,
        seller: null,
        priority: null,
        createDate: [],
        proposalDate: [],
        expectedSaleDate: [],
        saleDate: [],
      };
      return (localStorage.crmFilters = JSON.stringify(this.search));
    },
    restoreState(event) {
      this.selectedProposals = [];
      event.selection = [];
      event.filters = null;
      event.sortField = this.search.groupBy;
      event.expandedRowGroups = this.expandRows();
    },
    saveState(event) {
      event.sortField = this.search.groupBy;
      event.selection = [];
      event.filters = null;
    },
    toggleFilters(event) {
      this.$refs.op.toggle(event);
    },
    updateFilter(event) {
      this.$refs.op.hide();
      this.myFiltersChanged(event);
      this.search.filterLabel += " (Em Edição)";
      this.editingFilter = true;
    },
    deleteFilter(event) {
      this.$refs.op.hide();
      let message = `Tem a certeza que deseja apagar o filtro: ${event.data.name}?`;
      this.$confirm.require({
        header: "Apagar Filtro",
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          if (this.search.filter == event.data.id) {
            this.search.filter = null;
            this.search.filterLabel = "Os Meus Filtros";
            this.filtersChanged();
            this.showSearchButton = false
          }
          return userSettingsService
            .deleteFilter(event.data.id)
            .then(() => {
              return userSettingsService.getFilter("crmV2");
            })
            .then((filters) => {
              return this.filterList = filters;
            });
        },
        reject: () => {},
      });
    },
    cancelFiltersChanges() {
      this.editingFilter = false;
      return this.myFiltersChanged({ data: { id: this.search.filter } });
    },
    async saveFiltersChanges() {
      let bodyParams = {
        filters: JSON.stringify(this.search),
      };
      return userSettingsService
        .updateFilter(this.search.filter, bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: `Erro ao salvar o filtro`,
              detail: "",
              life: 3000,
            });
          }
        })
        .then(() => {
          return userSettingsService.getFilter("crmV2");
        })
        .then((filters) => {
          this.filterList = filters;
          this.editingFilter = false;
          return this.myFiltersChanged({ data: { id: this.search.filter } });
        });
    },
    changeStatus(proposal) {
      return (this.proposalChangeStatus = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.cleanSubject,
        statusId: proposal.status,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changeStatusCancel() {
      this.selectedProposals = null;
      return (this.proposalChangeStatus = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        statusId: null,
      });
    },
    changeStatusOk(response) {
      if (response.status == "ok") {
        this.initStreak();
      }
      return this.changeStatusCancel();
    },
    changeClosingProbability(proposal) {
      return (this.proposalClosingProbability = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.cleanSubject,
        probability: proposal.closing_probability,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changeClosingProbabilityCancel() {
      return (this.proposalClosingProbability = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        probability: null,
      });
    },
    changeClosingProbabilityOk(response) {
      if (response.status == "ok") {
        this.initStreak();
      }
      return this.changeClosingProbabilityCancel();
    },
    changeNextContact(proposal) {
      return (this.proposalNextContact = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.cleanSubject,
        nextContact: proposal.next_contact,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changeNextContactCancel() {
      return (this.proposalNextContact = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        nextContact: null,
      });
    },
    changeNextContactOk(response) {
      if (response.status == "ok") {
        this.initStreak();
      }
      return this.changeNextContactCancel();
    },
    changeSeller(proposal) {
      return (this.proposalChangeSeller = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.cleanSubject,
        sellerId: proposal.seller_id,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changeSellerCancel() {
      return (this.proposalChangeSeller = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        sellerId: null,
      });
    },
    changeSellerOk(response) {
      if (response.status == "ok") {
        this.initStreak();
      }
      return this.changeSellerCancel();
    },
    changeClient(proposal) {
      return (this.proposalChangeClient = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.cleanSubject,
        clientTypeId: proposal.client_type,
        contactId: proposal.contact_id,
        potential_client_name: proposal.potential_client_name,
        potential_client_person: proposal.potential_client_person,
        potential_client_telephone: proposal.potential_client_telephone,
        potential_client_email: proposal.potential_client_email,
        potential_client_comments: proposal.potential_client_comments,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changeClientCancel() {
      return (this.proposalChangeClient = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        clientTypeId: null,
        contactId: null,
        potential_client_name: null,
        potential_client_person: null,
        potential_client_telephone: null,
        potential_client_email: null,
        potential_client_comments: null,
      });
    },
    changeClientOk(response) {
      if (response.status == "ok") {
        this.initStreak();
      }
      return this.changeClientCancel();
    },
    changePriority(proposal) {
      return (this.proposalChangePriority = {
        show: true,
        proposalId: proposal.id,
        proposalSubject: proposal.cleanSubject,
        priorityId: proposal.priority,
        proposal: proposal,
        selected:
          this.selectedProposals && this.selectedProposals.length > 1
            ? this.selectedProposals
            : null,
      });
    },
    changePriorityCancel() {
      return (this.proposalChangePriority = {
        show: false,
        proposalId: null,
        proposalSubject: null,
        priorityId: null,
      });
    },
    changePriorityOk(response) {
      if (response.status == "ok") {
        this.initStreak();
      }
      return this.changePriorityCancel();
    },
    async mergedLeadsOk() {
      this.initStreak();
      return (this.showMergeLead = false);
    },
  },
};
</script>
<style>
.p-column-filter {
  width: 100%;
}

.p-tooltip {
  z-index: 5;
}
.p-datatable .p-datatable-loading-overlay {
  z-index: 5;
}
.vc-popover-content-wrapper {
  z-index: 20 !important;
}

.quarter-select {
  background-color: #fff;
  cursor: pointer;
}

.qs__icon.qs__icon--prefix {
  display: none !important;
}

.myGroupHeader {
  display: inline-flex;
  height: 25px;
  padding: 0 15px;
  align-items: center;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 15px;
  cursor: default;
  position: absolute;
  margin-left: 30px;
}

.p-datatable-scrollable .p-datatable-thead {
  z-index: 2 !important;
}

.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.streak-icon {
  transform: rotate(90deg);
}

.p-dialog .p-dialog-header-icons {
  display: none !important;
}

.align-center .p-column-header-content {
  justify-content: center !important;
}
th.align-center {
  justify-content: center !important;
}

.p-datatable .p-column-resizer {
  border: 1px solid rgb(0 0 0 / 3%) !important;
  width: 0.1em !important;
}

.p-datatable-emptymessage {
  text-align: center !important;
  display: block !important;
}

.p-datatable-wrapper {
  background-color: #fff !important;
}

.p-column-header-content {
  white-space: normal !important;
}
</style>
